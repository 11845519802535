import React from 'react';
import ResultSelectPageTemplate from "../../../../libs/templates/pageTemplates/ResultSelectPageTemplate";

const ResultSelection = () => <ResultSelectPageTemplate
  voucherLabel='Enter Your Vaucher'
  voucherFormIsSubmitOutside={false}
  isMenuEnabled={true}
/>;

export default ResultSelection;
